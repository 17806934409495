@import url('https://api.fontshare.com/v2/css?f[]=satoshi@700,300,401,400&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

.hide-scrollbar::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  .hide-scrollbar {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
::-webkit-scrollbar {
    width: 5px;
    height: 2px;
    
  }
  

::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   

::-webkit-scrollbar-thumb {
    background: #888; 
  }
  

::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }

  body{
    background-color: black;
  }
   .custom-sscrollbar ::-webkit-scrollbar {
    height: 5px;
  }
  

  .custom-sscrollbar ::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   

  .custom-sscrollbar ::-webkit-scrollbar-thumb {
    background: #888; 
  }
  

  .custom-sscrollbar ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }

  html{
    overflow-x: hidden;
  }

  .underlined {
    position: relative;
    margin-right: 1rem;
  }
  .underlined:after {
    content: "";
    position: absolute;
    bottom: -10px;
    left: 0;
    height: 7px;
    width: 100%;
    border: solid 2px #ffffff;
    border-color: #ffffff transparent transparent transparent;
    border-radius: 60%;
  }
  .small {
    font-size: 60%;
  }
  .big {
    font-size: 200%;
  }